import React from 'react'
import Layout from '../components/layout'
import { TermsOfUse } from '@tyson-foods/react-component-library'
import { Container } from "reactstrap";
import { Link } from "gatsby"
import  "../scss/terms-of-use.scss"




export default class Terms extends React.Component {
    render() {

        return (
            <Layout pageTitle="Terms of Use"
            metaDescription="Please read these terms of use carefully before using this site. By accessing this website, you agree to review our terms of use and are bound by them.">
                <Container fluid={true} >
                    <TermsOfUse
                        website="https://www.wrightbrand.com"
                        className="legal-container rich-text"
                        privacyPolicyLink={<Link to="/privacy-policy/">PRIVACY POLICY</Link>}
                    />
                </Container>
            </Layout>
        )
    }
}
